





























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
    computed: mapGetters(["getActiveRegion", "getRegionCountries"]),
})
export default class AddressFields extends Vue {
    @Prop() private ident!: string;

    @Prop({ default: false }) private onlyreq!: boolean;

    public str(str: string): string {
        if (str.length) {
            return `${this.ident} __ ${str}`;
        }
        return this.ident;
    }
}
