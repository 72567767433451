import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueScrollTo from 'vue-scrollto';
import './assets/styles/tailwind.scss';
/*
import iFrameResize from 'iframe-resizer/js/iframeResizer.contentWindow.js';
declare global {
    interface Window {
        iFrameResizer: { targetOrigin: string };
    }
}

window.iFrameResizer = window.iFrameResizer || {
    targetOrigin: 'http://mydomain.com'
}
*/

Vue.config.productionTip = false;

Vue.use(VueScrollTo);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
