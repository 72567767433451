





















































































































































import { Component, Vue } from "vue-property-decorator";
import AddressFields from "@/components/AddressFields.vue";
import HiddenFormFields from "@/components/HiddenFormFields.vue";
import ChoosePlan from "@/components/ChoosePlan.vue";
import Thanks from "@/components/Thanks.vue";
import Error from "@/components/Error.vue";
import { mapState, mapGetters } from "vuex";
import { Product } from "@/store";

@Component({
    components: {
        ChoosePlan,
        HiddenFormFields,
        AddressFields,
        Thanks,
        Error,
    },

    computed: {
        ...mapState({
            activeProduct: "activeProduct",
        }),
        ...mapGetters({
            getActiveProduct: "getActiveProduct",
        }),
    },
})
export default class HomeView extends Vue {
    public displayDiv = false;
    public sameAddress = true;
    public showOptOut = false;
    public isOpOutEi = false;
    public isOpOutTi = false;
    public isOpOutMi = false;
    public isOpOutSi = false;
    getActiveProduct!: Product;

    get isError() {
        return this.$route.name === "Error" ? true : false;
    }
    get isSuccess() {
        return this.$route.name === "Success" ? true : false;
    }
}
