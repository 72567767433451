import Vue from 'vue';
import Vuex from 'vuex';
import products from './products.json';
import countries from './countries.json';

Vue.use(Vuex);

interface Products {
    [key:string]:{
        [key:string]:Product
    }
}

export interface Product {
    [key:string]:string|number;
}

interface Countries {
    [key:string]:{
        [key:string]:string;
    }
}



export default new Vuex.Store({
    state: {
        activeRegion: 'UK',
        activeMixeTyp: 'bundle',
        products: products as Products,
        countryList: countries as Countries
    },
    getters: {
        getActiveType: state => state.activeMixeTyp,
        getActiveRegion: state => state.activeRegion,
        getActiveProduct: state => {
            const activeProduct =state.products[state.activeRegion][state.activeMixeTyp];
            return activeProduct
        },
        getRegionProducts: state => {
            const regionProducts =state.products[state.activeRegion];
            return regionProducts
        },
        getRegions: state => {
            return Object.keys(state.products);
        },
        getRegionCountries : state => {
            const regionCountries =state.countryList[state.activeRegion];
            return regionCountries
        },
    },
    mutations: {
        setActiveType: (state, payload) => {
            state.activeMixeTyp = payload;
        },
        setActiveRegion: (state, payload) => {
            state.activeRegion = payload;
        }
    },
    actions: {
        changeActiveProduct({commit}, payload) {
            commit('setActiveType', payload);
        },
        changeActiveRegion({commit}, payload) {
            commit('setActiveRegion', payload);
        }

    },
    modules: {},
});
